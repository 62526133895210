<template>
  <div>
    <v-app-bar 
      color="primary"
      height="50px" 
      clipped-left
      app
      class="header"
    >
      <!-- <v-app-bar-nav-icon @click.stop="draw" class="draw-aside-menu" color="#FFF"></v-app-bar-nav-icon> -->
      
      <!-- menu-hamburger -->
      <v-btn icon dark @click.stop="draw">
        <buger :active="activeBtn" />
      </v-btn>

      <v-toolbar-title
        style="
          font-size: 14px;
          text-align: center;
          width: 100px;
          color: #FFF;
          font-weight: 100;
          text-transform: uppercase;
          cursor: pointer;
        "
        @click="$router.push( { name: 'home'} )"
      >
        <!-- <img src="@/assets/img/logo.png" alt="Logo" class="logo"  @click="$router.push( { name: 'home'} )" /> -->
        Gestao <strong
          style="
            font-weight: 800;
            margin-left: 3px;
          "
        >Única</strong>
      </v-toolbar-title>

      <VueCustomScrollbar class="scroll-area"  :settings="settings" @ps-scroll-y="scrollHanle">
        <!-- <template v-for="(val, k) in listAcl">
          <v-toolbar-items class="hidden-sm-and-down header-menu-itens" :key="k">
            <v-btn
              tile
              dark
              text
              :to="{ name: k }"
            >{{ k }}</v-btn>
          </v-toolbar-items>
        </template> -->

        <template v-for="(item, key) in listaMenuOrdenado" >
          <v-toolbar-items class="hidden-sm-and-down header-menu-itens" :key="key">
            <v-btn
              tile
              dark
              text
              :to="{ name: key}"
            >{{ key }}</v-btn>
          </v-toolbar-items>
        </template>
      </VueCustomScrollbar>

      <v-spacer />

      <v-autocomplete
        label="Buscar"
        placeholder="Buscar"
        :items="listaMenu"
        outlined
        class="header-autocomplete"
        solo
        height="36px"
        flat
        dark
        prepend-icon="search"
        v-model="rota"
      >
        <template>
          <v-icon>search</v-icon>
        </template>
      </v-autocomplete>

      <!-- <v-btn
        tile
        depressed
        class="header-btn"
      >
        Relatórios
      </v-btn> -->

      <v-menu
        transition="slide-y-transition"
        offset-y
        min-width="200px"
      >
      <template v-slot:activator="{ on }">
        <v-btn
          text
          v-on="on"
          class="user-btn"
          dark
          tile 
        >
          {{ user.name }} ({{ user.role }})
          <i class="icon-gear"></i>
        </v-btn>
      </template>
      <v-list dense>
        <v-list-item v-if="termoAtual" @click="$router.push('/termo-consentimento')">
          <v-list-item-title>Contrato</v-list-item-title>
        </v-list-item>
        <v-list-item @click="abrirPerfil()">
          <v-list-item-title>Perfil</v-list-item-title>
        </v-list-item>
        <v-list-item @click="logaout()">
          <v-list-item-title>Sair</v-list-item-title>
        </v-list-item>
      </v-list>
    </v-menu>
    </v-app-bar>
    <Perfil />
  </div>
</template>

<script>
import Events from '@/core/service/events'
import storage from '@/core/service/localStorage'
import { filter, orderBy } from 'lodash'
import { mapGetters, mapActions } from 'vuex'
// import variables from '../../../assets/styles/helpers/_variables.scss'
// import VueCustomScrollbar from 'vue-custom-scrollbar'
// import buger from '@/views/components/burger'

export default {
  name: 'BrAppBar',
  components: {
    VueCustomScrollbar: () => import('vue-custom-scrollbar'),
    buger: () => import('@/views/components/burger'),
    Perfil: () => import('@/views/components/profile/index')
  },
  data: () => ({
    settings: {
      maxScrollbarLength: 60
    },
    activeFilter: null,
    activeBtn: false,
    rota: null
  }),

  watch: {
    rota (val) {
      this.$router.push({ name: val })
    },
  },

  mounted () {
    this.getUser()
    this.getTermos()
    this.getEntitiesTypes()
    Events.$on('aside::alterDrawer', (e) => {
      this.activeBtn = e
    })
  },
  methods: {
    ...mapActions('auth', ['getUser', 'clearUser']),
    ...mapActions('termoConsentimento', ['getTermos']),
    ...mapActions('entities', ['getEntitiesTypes']),
    ...mapActions('users', ['getItens']),
    draw () {
      this.activeBtn = !this.activeBtn
      Events.$emit('appBar::drawer')
    },
    
    scrollHanle(evt) {
      // eslint-disable-next-line no-console
      console.log(evt)
    },

    logaout () {
      storage.remove('auth-token')
      storage.remove('jwt')
      this.clearUser()
      this.$router.replace('/auth')
      location.reload(true)
    },

    abrirPerfil () {
      this.getItens({ self: true }).then( result => {
        Events.$emit('perfil::openModalPerfil', result.data.mapResult[0])
      }).catch(e => { console.log(e) })},

    ordernar (val) {
      let ordered = {}

      let orderByGestao = Object.keys(val).sort()
      orderByGestao = orderBy(orderByGestao, it => {
        if (it === 'gestao') return -1
        return 0
      })

      orderByGestao.forEach(function(key) {
        ordered[key] = val[key];
      })

      return ordered
    }
  },
  computed: {
    ...mapGetters('roles', {
      listaMenu: 'listAcl'
    }),
    ...mapGetters('auth', ['user']),
    ...mapGetters('users', ['listaMenu', 'listaMenuTratado']),
    ...mapGetters('termoConsentimento', ['termoAtual']),
    routes () {
      return  filter(this.$router.options.routes[0].children, item => {
        if (item.meta && item.meta.visible === true) return true
      })
    },

    listaMenuOrdenado () {
      return this.ordernar(this.listaMenuTratado)
    }
  }
}
</script>

<style lang="scss">
  @import '../../../assets/styles/helpers/shareds';
  @import '../../../assets/styles/components/header';
  .header {
    .logo {
      cursor: pointer;
    }
    &-menu{
      &-itens {
        .v-btn__content {
          text-transform: capitalize;
        }
      }
    }
  }
</style>